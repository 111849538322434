.hikaku h1 {
  font-size: x-large;
  font-weight: normal;
}

.hikaku table {
  border-collapse: collapse;
  margin: 0.7rem auto 4.9rem auto;
}


.hikaku tbody tr:nth-of-type(odd) {
  background-color: #eaf5fe;
}

.hikaku tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}


.hikaku th {
  font-weight: normal;
}

.hikaku thead th {
  background-color: #6e6eff;
  color: #FFFFFF;
}

.hikaku tbody th,
.hikaku tbody td {
  border-left: thin solid #dddddd;
  border-right: thin solid #dddddd;
}

.hikaku caption {
  font-size: large;
}

.hikaku .flow {
  white-space: nowrap;
}

.hikaku thead th,
.hikaku thead td {
  padding: 0.7rem 0.7rem;
}

.hikaku tbody th,
.hikaku tbody td {
  padding: 1.4rem 0.7rem;
}

.hikaku .bank {
  background-color: #e2e2e2;
  border: 1px solid #000000;

}

.hikaku .app {
  background-color: #ece2fe;
  border: thin solid #b300aa;
}

.hikaku .allow {
  background-color: yellow;
  font-weight: bold;
}

.hikaku .sub {
  color: gray;
  font-size: small;
}

.hikaku .logo {
  width: 70%;
}